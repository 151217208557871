import React from "react"

const NetlifyNeedsThisPage = () => {
  return (
    <>
      

      <form method="post" data-netlify="true" name="submission">
        <input type="hidden" name="form-name" value="submission" />
        <input id="name" name="name" type="text" placeholder="Name" value="" />
        <input id="email" name="email" type="text" placeholder="Email" value="" />
        <textarea name="notes" type="textarea" placeholder="Type your submission here"></textarea>
        <select name="categories"></select>
      </form>
    </>
  )
}

export default NetlifyNeedsThisPage
